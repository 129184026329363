/*
* 업무구분: 계약관리
* 화 면 명: MSPPM870M
* 화면설명: 유지체크명세 메인
* 접근권한: 
* 작 성 일: 2022.12.22
* 작 성 자: 김연숙
*/
<template>
  <ur-page-container class="msp" title="유지체크명세" :show-title="false" type="subpage"  :topButton="true" :topButtonBottom="topButtonBottom"> <!-- :topButton="true" top으로 올리는 버튼 나오게 -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <mo-button class="btn_popup_back" @click="fn_HistoryBack()"/>
              <div slot="nav" class="icon-wrapper" @click="fn_HistoryBack()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '유지체크명세' : '유지체크명세'}}</div>
              <div slot="action" @click="fn_OpenMSPPM871P">
                <mo-icon>search</mo-icon>
              </div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 : 스와이프 영역 -->
          <!-- <template #collapsing>
            <div class="ns-swiper-area no-pagination mb15"> -->
              <!-- 서비스 연동 시(sitcky 안됨) -->
              <!-- <mo-carousel v-if="lv_HomeBannIapList.length > 0" :number-of-page="lv_HomeBannIapList.length" ref="swiper" height="auto" :no-rotate="lv_NoRotate" :swiper-option="lv_SwiperOption" :prevButton="false" :nextButton="false" style="padding:0 24px;">
                <template v-for="(item, idx) in lv_HomeBannIapList" v-slot:[`${idx+1}`]>
                  <div v-if="item.iapKndCd === 'default'" class="ns-banner-box ns-style2" :key="idx">
                    <strong class="title">유지체크명세<br> 이벤트배너1 입니다.</strong>
                  </div>
                </template>
              </mo-carousel> -->
              <!-- <mo-carousel number-of-page="1" ref="swiper" height="auto" :no-rotate="lv_NoRotate" :swiper-option="lv_SwiperOption" :prevButton="false" :nextButton="false" style="padding:0 24px;">
                <template #1>
                  <div class="ns-banner-box ns-style2">
                    <strong class="title">유지체크명세<br> 이벤트배너1 입니다.</strong>
                  </div>
                </template>
              </mo-carousel>
            </div>
          </template> -->
          <!-- collapsing 영역 : 스와이프 영역 //-->

          <!-- stickey 영역 -->
          <template #sticky>
            <!-- 탭 -->
            <mo-tab-box default-idx="1" primary class="ns-move-tab-box tween">
              <!-- tab id loop -->
              <mo-tab-label idx="1" @click="fn_ChangeIdx('1')">해당</mo-tab-label>
              <mo-tab-label idx="2" @click="fn_ChangeIdx('2')">확정</mo-tab-label>
              <!-- tab id loop //-->
            </mo-tab-box>
            <!-- 탭 //-->

            <!-- 검색결과 카테고리 출력 -->
            <ur-box-container v-if="showReturnStr && !isInit" alignV="start"  direction="column" class="search-result-box bd-b-Ty1">
              <strong class="tit">검색결과</strong>
              <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
                <mo-tab-label idx="1" v-if="isMngr && pOrgData870M !== {}" @click="fn_OpenMSPPM871P">
                  <span class="txt">{{pOrgData870M.hofOrg.label}}</span> 
                </mo-tab-label>
                <mo-tab-label idx="1" v-if="isMngr && pOrgData870M !== {}" @click="fn_OpenMSPPM871P">
                  <span class="txt">{{pOrgData870M.dofOrg.label}}</span> 
                </mo-tab-label>
                <mo-tab-label idx="1" v-if="isMngr && pOrgData870M !== {}" @click="fn_OpenMSPPM871P">
                  <span class="txt">{{pOrgData870M.fofOrg.label}}</span> 
                </mo-tab-label>
                <mo-tab-label idx="1" v-if="isMngr && pOrgData870M !== {}" @click="fn_OpenMSPPM871P">
                  <span class="tit">컨설턴트</span>
                  <span class="txt">{{pOrgData870M.cnslt.label}}</span> 
                </mo-tab-label>
                <mo-tab-label idx="1" v-show="lv_ReturnStr[0] !== ''" @click="fn_OpenMSPPM871P">
                  <span class="tit">계약자명</span>
                  <span class="txt">{{lv_ReturnStr[0]}}</span> 
                </mo-tab-label>
                <mo-tab-label idx="1" v-show="lv_ReturnStr[1] !== '' && lv_ReturnStr[2] !== ''" @click="fn_OpenMSPPM871P">
                  <span class="tit">유지횟수</span>
                  <span class="txt">{{lv_ReturnStr[1] + '회~' + lv_ReturnStr[2] + '회'}}</span> 
                </mo-tab-label>
                <mo-tab-label idx="1" v-show="lv_ReturnStr[3] !== ''" @click="fn_OpenMSPPM871P">
                  <span class="tit">산출기준</span>
                  <span class="txt">{{lv_ReturnStr[3]}}</span> 
                </mo-tab-label>
                <mo-tab-label idx="1" v-show="lv_ReturnStr[4] !== ''" @click="fn_OpenMSPPM871P">
                  <span class="tit">납입상태</span>
                  <span class="txt">{{lv_ReturnStr[4]}}</span> 
                </mo-tab-label>
                <mo-tab-label idx="1" v-show="lv_ReturnStr[5] !== ''" @click="fn_OpenMSPPM871P">
                  <span class="tit">상품구분</span>
                  <span class="txt">{{lv_ReturnStr[5]}}</span> 
                </mo-tab-label>
                <mo-tab-label idx="1" v-show="lv_ReturnStr[6] !== ''" @click="fn_OpenMSPPM871P">
                  <span class="tit">납입방법</span>
                  <span class="txt">{{lv_ReturnStr[6]}}</span> 
                </mo-tab-label>
                <mo-tab-label idx="1" v-show="lv_ReturnStr[7] !== ''" @click="fn_OpenMSPPM871P">
                  <span class="tit">이체일</span>
                  <span class="txt">{{lv_ReturnStr[7]}}</span> 
                </mo-tab-label>
              </mo-tab-box>
            </ur-box-container>
            <!-- 검색결과 카테고리 출력 //-->

            <!-- 총건수 -->
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum">
              <mo-list-item>
                <mo-checkbox v-model="isOneOrMoreCheck" @input="checkAll" :disabled="isEmptyView" size="small"/>
                <div class="list-item__contents">
                  <div class="list-item__contents__title align-item-center">
                    <span class="sum" v-if="checkCount === 0">총 <b class="crTy-blue3">{{ totCnt }}</b>명</span>
                    <span class="sum" v-else><strong class="crTy-blue3">{{ totCnt }}</strong> 건 중 <strong class="crTy-blue3">{{ checkCount }}</strong> 명 선택</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <!-- 총건수 //-->
          </template>
          <!-- stickey 영역 //-->

          <!-- scroll 영역 -->
          <template #scroll>
            <MSPPM872D ref="refPM872D" v-if="lv_index === '1'" :pMUkepChkSpcf01VO="pMUkepChkSpcf01VO" :isEmptyView="isEmptyView"
                                       @checkCount="fn_CheckSelectedItemCount" @fn_ContNoDecrypt="fn_ContNoDecrypt" @fn_DetailResetBtnClick="fn_DetailResetBtnClick" @fn_selInqrStrYmd="fn_selInqrStrYmd" @positionUp="fn_topButtonBottom"
                                       :scukepChkSpcfMobl01VO="lv_scukepChkSpcfMobl01VO" :scukepChkSpcfMobl02VO="lv_scukepChkSpcfMobl02VO" :exptTableData="lv_exptTableData" :initTableData="lv_initTableData" :orgData="pOrgData870M"/>
            <MSPPM876D ref="refPM876D" v-if="lv_index === '2'" :pMUkepChkSpcf01VO="pMUkepChkSpcf01VO" :isEmptyView="isEmptyView"
                                       @checkCount="fn_CheckSelectedItemCount" @fn_ContNoDecrypt="fn_ContNoDecrypt" @fn_DetailResetBtnClick="fn_DetailResetBtnClick" @fn_selInqrStrYmd="fn_selInqrStrYmd" @positionUp="fn_topButtonBottom"
                                       :scukepChkSpcfMobl01VO="lv_scukepChkSpcfMobl01VO" :scukepChkSpcfMobl02VO="lv_scukepChkSpcfMobl02VO" :exptTableData="lv_exptTableData" :initTableData="lv_initTableData" :orgData="pOrgData870M"/>
            
            
            <!-- 해당유지율 그래프 MSPPM871P -->
            <mo-bottom-sheet v-if="lv_graphData.length === 4" ref="bottomSheetGraph"  class="ns-bottom-sheet msp-bottomSt-ty1 z-index70 closebtn">
              <div class="ns-btn-close" @click="fn_InitBottomSheet()"></div>  <!-- close 버튼 Sample -->
              <div class="content-area graph-area">
                <!-- 해당유지율 -->
                <div class="graph-box fex-clumn">
                  <strong class="tit">해당유지율</strong>
                  <ul class="graph-lst fex-rw">
                    <li class="lst-item fex-clumn">
                      <!-- 그레프1 들어갈 자리 입니다. <2022-12-21 수정 : 색상코드값 추가>
                        1) 그래프 크기값등 폰트 크기 내용 입니다.
                            ㄱ. 전체 그래프 크기값은 : 가로: 130px, 세로: 130px입니다. 
                            ㄴ. 흰색 영역의 크기값은 (퍼센트 글자 표시되는) : 가로: 110px, 세로: 110px 입니다.
                            ㄷ. 테두리 영역 (색상이 표시되는 테두리영역) 크기값은: 20px입니다.
                            ㄹ. 폰트크기
                                - 숫자 폰트크기: font-size:30px, font-weight(폰트굵기): bold (굵게)입니다.
                                - 퍼센트 폰트크기: font-size:20px, font-weight(폰트굵기): normal (보통)입니다.

                        2) 그래프 색상코드값 입니다. 
                            ㄱ. 빈 영역 색상코드 값 : #EEE
                            ㄴ. 기본 색상 코드값 : #519EF6
                            ㄷ. 유지율 80% 이상일때 색상 코드값 : #FB7655
                            ㄹ. 유지율 85% 이상일때 색상 코드값 : #FFB60D
                            ㅁ. 유지율 90% 이상일때 색상 코드값 : #66C8C9                                                                                                                                                                                                                                                                                                                                  
                      -->
                      <div class="graph whS1">
                        <div id="chartPie0" style="position: relative; width: 150px; height: 150px; margin: auto; background: #FFFFFF;"></div>
                      </div>
                      <span class="txt">{{ lv_graphData[0].Title }}</span>
                    </li>
                    <li class="lst-item fex-clumn">
                      <!-- 그레프2 들어갈 자리 입니다. -->
                      <div class="graph whS1">
                        <div id="chartPie1" style="position: relative; width: 150px; height: 150px; margin: auto; background: #FFFFFF;"></div>
                      </div>
                      <span class="txt">{{ lv_graphData[1].Title }}</span>
                    </li>
                  </ul>
                </div>
                <!-- 해당유지율 //-->
                <!-- 확정유지율 -->
                <div class="graph-box fex-clumn">
                  <strong class="tit">확정유지율</strong>
                  <ul class="graph-lst fex-rw">
                    <li class="lst-item fex-clumn">
                      <!-- 그레프 들어갈 자리 -->
                      <div class="graph whS1">
                        <div id="chartPie2" style="position: relative; width: 150px; height: 150px; margin: auto; background: #FFFFFF;"></div>
                      </div>
                      <span class="txt">{{ lv_graphData[2].Title }}</span>
                    </li>
                    <li class="lst-item fex-clumn">
                      <!-- 그레프 들어갈 자리 -->
                      <div class="graph whS1">
                          <div id="chartPie3" style="position: relative; width: 150px; height: 150px; margin: auto; background: #FFFFFF;"></div>
                      </div>
                      <span class="txt">{{ lv_graphData[3].Title }}</span>
                    </li>
                  </ul>
                </div>
                <!-- 확정유지율 //-->
              </div>
            </mo-bottom-sheet>
            <!-- 해당유지율 그래프 MSPPM871P// -->
          </template>
          <!-- scroll 영역 //-->
        </mo-collapsing-header-view>
      </div>

      <!-- 계약 로딩 표시 -->
      <div class="msp loading--dim msp-in-page" v-show="isReading">
        <div class="preloader-bubbling">
          <span id="bubbling_1"></span>
          <span id="bubbling_2"></span>
          <span id="bubbling_3"></span>
        </div>
        <div class="loading-text-box">
          <span class="title">{{loadingMsg}}</span>
          <span class="txt" v-html="loadingSubMsg" />
        </div>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'
import MSPPM872D from '@/ui/pm/MSPPM872D' // 해당 디비전
import MSPPM876D from '@/ui/pm/MSPPM876D' // 확정 디비전
import MSPPM871P from '@/ui/pm/MSPPM871P' // 조회조건
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Screen from '~systems/mixin/screen'

require('@/assets/lib/rMateChartH5/Assets/Css/rMateChartH5.css')
require('@/assets/lib/rMateChartH5/JS/rMateChartH5.js')
require('@/assets/lib/rMateChartH5/LicenseKey/rMateChartH5License.js')
require('@/assets/lib/rMateChartH5/JS/rMateChartVar.js')

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPPM870M',
  // 현재 화면 ID
  screenId: 'MSPPM870M',
  mixins: [Screen],
  // 컴포넌트 선언
  components: {
    MSPPM872D,
    MSPPM876D,
    MSPPM871P,
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      lv_HeaderviewScrollCompID: '',
    
      // 제어 변수
      isMngr: false, // 지점장 여부
      isFirst: true, // 최초조회 구분값
      isDcd: false,  // 확정유지율조회여부값
      isOneOrMoreCheck: false,
      isSearched: false,      // 검색실행여부
      isInit: false, // 검색 초기화 여부
      isUkep: false, // 예상유지율반영 버튼 선택 여부

      // 팝업 연동
      popup871: {}, // MSPPM871P 상세조회
      pOrgData870M: {}, // 최근 조직 조회 데이터
      lv_ReturnStr: [], // 상세조회조건 텍스트 배열
      
      // 데이터 변수
      lv_index: '1',  // 탭 인덱스
      // 이벤트 배너
      lv_HomeBannIapList: [], // 홈배너알림 List
      lv_NoRotate: false,
      lv_SwiperOption: {
        centerSlides: true,
        slidesPerView: 1.1,
        spaceBetween: 10,
        autoplay: {
          delay: 20000,
          disableOnInteraction: false, 
        }
      },
      // 유지체크명세 리스트
      checkCount: 0,
      custRltnList: [], // 관계 정보
      pMUkepChkSpcf01VO: [], // 인피니트 바인딩 리스트VO
      userInfo: {}, // 사용자정보 obj
      isLoading: false,
      isEmptyView: false, // 데이터 없음 화면
      lv_cnsltNo: '', // 컨설턴트 번호
      lv_inputParam: { // 조회 서비스호출 Obj
        dofOrgNo: '', // 지역단조직번호
        fofOrgNo: '', // 지점조직번호
        cnsltNo: '', // 컨설턴트번호
        cnsltNm: '', // 컨설턴트명
        custNm: '', // 고객명
        teamCd: '00',
        atntScCd: '900',
        grno: '',
        nwmanScCd: '0',
        tmsScCd01: '', // 회차구분코드1
        tmsScCd02: '', // 회차구분코드2
        inqrStrYm: '',
        inqrEndYm: '',
        ukepScCd: '01', // 조회유형
        inqrStndCd: '0', // 산출기준
        statScCd: '3',  // 납입상태
        prdtLclsfCd: '99',// 상품구분
        inqrScCd: '99',   // 납입방법
        trsfDdScVal: '00',// 이체일
      },
      sel_group1: '2',  // 조회유형 시작회차
      sel_group2: '49',  // 조회유형 종료회차
      totCnt: 0, // 누적 총 조회건수
      inqrStrYm_Init: '',
      // 예상유지율 결과 VO
      lv_scukepChkSpcfMobl01VO: [],
      lv_scukepChkSpcfMobl02VO: [],
      lv_first01VO: [],
      lv_exptTableData: [], // 예상해당유지율 - 환산월초 기준유지율
      lv_exptTableDataEtc: [],  // lv_exptTableData에서 사용하지 않으나 필요한 데이터(2~13회 평균)
      lv_initTableData: [], // 예상유지율 초기화
      lv_graphData: [],     // 해당유지율 그래프
      // rMate 변수
      firstColor: '0x519ef6',
      secondColor: '0xeee',
      firstAlpha: '1',
      secondAlpha: '1',

      isReading: false,
      loadingMsg: '고객 정보 조회중',
      loadingSubMsg: '2~3분 정도 소요될 수 있습니다.',

      topButtonBottom: 0
      
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    showReturnStr () {
      if (this.isMngr && !_.isEmpty(this.pOrgData870M)) {
        return true
      }
      if (this.lv_ReturnStr.length > 0) {
        for (let i in this.lv_ReturnStr) {
          if (!this.$bizUtil.isEmpty(this.lv_ReturnStr[i])) return true
        }
      }
      return false
    }
  },

  /** watch 정의 영역 */
  watch: {
    checkCount () {
      if (this.pMUkepChkSpcf01VO.length === 0) {
        this.isOneOrMoreCheck = false
      } else {
        if (this.checkCount === this.pMUkepChkSpcf01VO.length) {
          this.isOneOrMoreCheck = true
        } else {
          this.isOneOrMoreCheck = false
        }
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo

    this.lv_cnsltNo = this.userInfo.userId // 컨설턴트 번호

    this.lv_inputParam.cnsltNo = this.lv_cnsltNo // 컨설턴트번호
    this.lv_inputParam.dofOrgNo = this.userInfo.onpstDofNo // 지역단조직번호
    this.lv_inputParam.fofOrgNo = this.userInfo.onpstFofOrgNo // 지점조직번호

    // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
    if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
      this.isMngr = true
    }

    // 사랑온 미니 홈배너(이벤트배너 임시 세팅)
    // this.fn_SelListHomeBannIapForMSP()

    // 기준년도 조회
    this.fn_selInqrStrYmd()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    window.vue.getStore('progress').dispatch('NONE')
    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    // 바텀시트 초기화
    this.fn_InitBottomSheet()
    if (this.lv_index === '1') this.$refs.refPM872D.fn_InitBottomSheet()
    else this.$refs.refPM876D.fn_InitBottomSheet()
  },

  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
 methods: {
   /******************************************************************************
    * Function명 : fn_HistoryBack
    * 설명       : 뒤로가기 버튼
    ******************************************************************************/
   fn_HistoryBack () {
     if (this.checkCount > 0) this.fn_confirm()
     else {
      if (this.$route.query?._isMenuItem) {
        this.$router.push({name: 'MSPBC002M'})
        return
      }
      this.$router.go(-1)
     }
   },

   /******************************************************************************
     * Function명 : fn_ChangeIdx
     * 설명       : 해당 / 확정 Tab Change
     ******************************************************************************/
    fn_ChangeIdx (str) {
      let lv_Vm = this

      // post 호출 전 Progress 타입 설정
      window.vue.getStore('progress').dispatch('NONE')
      lv_Vm.loadingMsg = '유지 정보 조회중'
      lv_Vm.fn_SetProgressMsgPop(true)

      if (str === '1') {
        lv_Vm.lv_index = '1'
        // 해당
        lv_Vm.lv_inputParam.ukepScCd = '01'
      } else {
        lv_Vm.lv_index = '2'
        // 확정
        lv_Vm.lv_inputParam.ukepScCd = '02'
      }

      // 리스트 재조회
      lv_Vm.fn_Submit()
    },

   /******************************************************************************
    * Function명  : fn_OnChangCarousel
    * 설명        : mo-carousel 컴포넌트 OnChange Event Function
    * Params      : pIndex - 현재 활성화 된 INDEX
    ******************************************************************************/
    fn_OnChangCarousel (pIndex) {
      // console.log('fn_OnChangCarousel call', pIndex)
      /* 
        첫번째 배너에서 마지막 배너로 역방향으로 움직였을 때, 
        마지막 배너에서 첫번째 배너로 순방향으로 움직였을 때 
        클릭이벤트가 적용되지 않아 index를 강제로 맞추어줌
      */
      let t_BannCnt = this.lv_HomeBannIapList.length
      if ( pIndex === 0 ) { // 첫번째 배너에서 마지막 배너로 역방향으로 움직였을 때
        // 강제로 마지막배너 index를 맞추어줌
        setTimeout(() => {this.$refs.swiper.moveTo(t_BannCnt)}, 700)
      } else if ( pIndex > t_BannCnt ) { // 마지막 배너에서 첫번째 배너로 순방향으로 움직였을 때
        // 강제로 마지막배너 index를 맞추어줌
        setTimeout(() => {this.$refs.swiper.moveTo(1)}, 700)
      }
    },

    /******************************************************************************
    * Function명  : fn_SelListHomeBannIapForMSP ()
    * 설명        : 사랑온 미니 홈배너
    * Params      : N/A
    * Return      : 
    ******************************************************************************/
    fn_SelListHomeBannIapForMSP () {
      this.lv_HomeBannIapList = []
      let t_DefaultBanner = { iapKndCd: 'default' }
      let lv_Vm = this
      let pParams = {
        nextKey: '1',
        readCount: 6
      }
      const trnstId = 'txTSSBC03SC'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if ( response && response.body && response.body.returnList) {
            // 홈배너에 5개까지 노출함 - 6개이상인 경우 4개메시지 + '더 많은 접촉정보 알림 보기' 문구배너로 구성함
            if ( response.body.returnList.length <= 5 ) { // 5개이하는 조회내역 그대로
              // 1개인 경우는 자동Swipe off(조회된 결과가 0인 경우가 기본배너만 설정하기때문에 1개인 경우)
              if ( response.body.returnList.length === 0 ) {
                lv_Vm.lv_SwiperOption = {}
                lv_Vm.lv_NoRotate = true
              }

              // 기본배너 설정
              lv_Vm.lv_HomeBannIapList.push(_.cloneDeep(t_DefaultBanner))


              // 조회된 결과 배너 설정
              let t_RtnList = response.body.returnList
              t_RtnList.forEach((item) => {
                lv_Vm.lv_HomeBannIapList.push(_.cloneDeep(item))
              })
            } else { // 5개 초과
              // 기본배너 설정
              lv_Vm.lv_HomeBannIapList.push(_.cloneDeep(t_DefaultBanner))

              //  조회된 결과 배너 4건까지 설정
              for ( let i = 0; i < 4; i++ ) {
                let t_TmpObj = response.body.returnList[i]
                lv_Vm.lv_HomeBannIapList.push(_.cloneDeep(t_TmpObj))
              }

              // '더 많은 접촉정보 알림 보기' 문구배너 추가 생성(5번째 알림정보를 가지고 수정)
              let t_TmpObjOfFiveRow = response.body.returnList[4]
              
              // 개별알람에 대한 정보는 초기화 후 설정
              t_TmpObjOfFiveRow.cntcSno = ''
              t_TmpObjOfFiveRow.custCntcId = ''
              t_TmpObjOfFiveRow.iapCntnt = lv_Vm.lv_IapCntntMoreInfoTxt
              t_TmpObjOfFiveRow.iapInqrKeyVal = ''
              t_TmpObjOfFiveRow.iapMatrSn = ''
              t_TmpObjOfFiveRow.occuYmd = ''
              lv_Vm.lv_HomeBannIapList.push(_.cloneDeep(t_TmpObjOfFiveRow))
            }
          } else {
            // 기본배너 설정
            lv_Vm.lv_SwiperOption = {}
            lv_Vm.lv_NoRotate = true
            lv_Vm.lv_HomeBannIapList.push(_.cloneDeep(t_DefaultBanner))
          }
        })
        .catch(function (error) {
          window.vue.error(error)

          // 기본배너 설정
          lv_Vm.lv_SwiperOption = {}
          lv_Vm.lv_NoRotate = true
          lv_Vm.lv_HomeBannIapList.push({ 'iapKndCd': 'default' })
        })
        // .finally(function () {
        //   lv_Vm.lv_IsCompletedLoad.selListHomeBannIapForMSP = true
        // })
    },

   /*********************************************************
    * Function명: checkAll
    * 설명: 조회목록 전체 선택
    * Params: N/A
    * Return: N/A
    *********************************************************/
    checkAll () {
      let lv_Vm = this

      for (let i in lv_Vm.pMUkepChkSpcf01VO) {
        if (lv_Vm.isOneOrMoreCheck) {
          lv_Vm.pMUkepChkSpcf01VO[i].checked = true
        } else {
          lv_Vm.pMUkepChkSpcf01VO[i].checked = false
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_CheckSelectedItemCount
     * 설명       : 체크박스 클릭 핸들러
     ******************************************************************************/
    fn_CheckSelectedItemCount (param) {
      let lv_Vm = this

      // 디비전의 체크 리스트 길이 동기화
      lv_Vm.checkCount = param.checkCount
    },

    /******************************************************************************
     * Function명 : fn_InitBottomSheet
     * 설명       : 바텀시트 초기화
     ******************************************************************************/
    fn_InitBottomSheet() {
      let lv_Vm = this
      
      if (lv_Vm.$refs !== undefined) {
        if (lv_Vm.$refs.bottomSheetGraph !== undefined) {
          lv_Vm.$refs.bottomSheetGraph.close()
        }
      } 

    },
  
    /******************************************************************************
     * Function명 : fn_Submit
     * 설명       : 조회 버튼 클릭 이벤트
     ******************************************************************************/
    fn_Submit () {
      let lv_Vm = this

      lv_Vm.totCnt = 0
      lv_Vm.checkCount = 0
      lv_Vm.pMUkepChkSpcf01VO = [] // 인피니트 바인딩 데이터
      lv_Vm.isEmptyView = false // 데이터가 없습니다 visible 여부
      lv_Vm.fn_DataSet() // 조회 파라미터 세팅      

      lv_Vm.fn_GetService() // 서비스 호출
    },

    /******************************************************************************
     * Function명 : fn_selInqrStrYmd
     * 설명       : 기준년도 조회 메소드
     ******************************************************************************/
    fn_selInqrStrYmd () {
      const trnstId = this.$pmUtil.getTrnstId()
      const auth = 'S'
      let inputData = {}

      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)

      // post 호출 전 Progress 타입 설정
      window.vue.getStore('progress').dispatch('NONE')
      this.loadingMsg = '유지 정보 조회중'
      this.fn_SetProgressMsgPop(true)

      let commHeader = {
        eaiId: '',
        fahrTrnId: '',
        requestSystemCode: '',
        reqSrvcNm: 'SCUkepChkSpcfCtlImpl',
        reqMthdNm: 'selInqrStrYmd',
        targetSystemCode: 'sli.ic',
        reqVONm: 'sli.ch.vo.sc.ukeprat.SCUkepChkSpcfListVO',
        resVONm: 'sli.ch.vo.sc.ukeprat.SCUkepChkSpcfListVO'
      }
      this.$pmUtil.eaiInvoke(inputData, trnstId, auth, commHeader, this.fn_selInqrStrYmdHandler)
    },
    fn_selInqrStrYmdHandler (response) {
      let lv_Vm = this
      if (!response || !response.body) {
        return
      }
      // 서버 데이터 가져오기
      if (response.body !== null) {
        lv_Vm.inqrStrYm_Init = response.body.inqrStrYm + '01'
        // 조회
        this.fn_Submit()
      }
    },

    /******************************************************************************
     * Function명 : fn_DataSet
     * 설명       : 조회전 데이터 파라미터 세팅
     ******************************************************************************/
    fn_DataSet () {
      let lv_Vm = this

      // 지점장 조회시 구분
      if (!_.isEmpty(lv_Vm.pOrgData870M) && lv_Vm.isMngr === true) {
        lv_Vm.lv_inputParam.cnsltNo  = lv_Vm.pOrgData870M.cnslt.key // 선택한 컨설턴트 번호
        lv_Vm.lv_inputParam.fofOrgNo = lv_Vm.pOrgData870M.fofOrg.key // 선택한 지점조직번호
        lv_Vm.lv_inputParam.dofOrgNo = lv_Vm.pOrgData870M.dofOrg.key // 선택한 지점단조직번호
      } else {
        lv_Vm.lv_inputParam.cnsltNo  = lv_Vm.lv_cnsltNo // 컨설턴트 번호
      }
       
      if (lv_Vm.lv_inputParam.cnsltNo === '0') {
        lv_Vm.lv_inputParam.cnsltNo = ''
      }

      // 고정값
      if (this.getStore('userInfo').getters.getUserInfo.userScCd === '11' && lv_Vm.lv_inputParam.inqrStndCd !== '0') {
        lv_Vm.lv_inputParam.tmsScCd01 = '11' // 회차구분코드1
        lv_Vm.lv_inputParam.tmsScCd02 = '96' // 회차구분코드2
      } else {
        lv_Vm.lv_inputParam.tmsScCd01 = '00' // 회차구분코드1
        lv_Vm.lv_inputParam.tmsScCd02 = '90' // 회차구분코드2
      }

      // ukepScCd(조회유형): 초회 진입 시 01(해당)
      // 해당유지율 그래프 바텀시트 호출을 위한 루프 구분
      if (lv_Vm.lv_index === '1') {
        lv_Vm.lv_inputParam.inqrStrYm = moment(lv_Vm.inqrStrYm_Init).subtract(Number(lv_Vm.sel_group1) - 1, 'months').format('YYYYMM')
        lv_Vm.lv_inputParam.inqrEndYm = moment(lv_Vm.inqrStrYm_Init).subtract(Number(lv_Vm.sel_group2) - 1, 'months').format('YYYYMM')
      } else {
        // ukepScCd(조회유형): 02(확정)
        lv_Vm.lv_inputParam.inqrStrYm = moment(lv_Vm.inqrStrYm_Init).subtract(Number(lv_Vm.sel_group1), 'months').format('YYYYMM')
        lv_Vm.lv_inputParam.inqrEndYm = moment(lv_Vm.inqrStrYm_Init).subtract(Number(lv_Vm.sel_group2), 'months').format('YYYYMM')
      }

      if (lv_Vm.lv_inputParam.inqrStrYm.indexOf('-') >= 0) {
        lv_Vm.lv_inputParam.inqrStrYm = lv_Vm.lv_inputParam.inqrStrYm.replace(/-/gi, '') // 조회시작일자
      }
      if (lv_Vm.lv_inputParam.inqrEndYm.indexOf('-') >= 0) {
        lv_Vm.lv_inputParam.inqrEndYm =lv_Vm.lv_inputParam.inqrEndYm.replace(/-/gi, '') // 조회종료일자
      }
    },

    /******************************************************************************
     * Function명 : fn_GetService
     * 설명       : 서비스 호출
     ******************************************************************************/
    fn_GetService () {
      const trnstId = 'txTSSPM40S3'
      const auth = 'S'
      let pParams = this.lv_inputParam
      let confirm = this.getStore('confirm')

      this.isLoading = true
      
      return this.post(this, pParams, trnstId, auth)
        .then(this.fn_GetServiceHandler)
        .catch(error => {
          this.isEmptyView = true;
          window.vue.error(error);
          this.getStore("confirm").dispatch("ADD", error);
        })
        .finally(() => {
          this.fn_SetProgressMsgPop(false)
          this.isLoading = false
        })  
    },
    fn_GetServiceHandler (response) {
      if (!response) return;

      let lv_Vm = this
      // 서버 데이터 가져오기
      if (response.body !== null) {
        let responseList = response.body.pMUkepChkSpcf01VO
        let scukepChkSpcfListVO = response.body
        let idx = 1

        responseList.forEach((item) => {
          item.idx = idx
          idx++
          item.ck = 0
          item.checked = false
          item.contNo = item.contNo
          item.contNo1 = '*' + item.contNo.substr(13, 4)
          item.clctBomthAmtCommas = lv_Vm.$bizUtil.numberWithCommas(item.clctBomthAmt.amount)
          item.ukepMpadPrmCommas = lv_Vm.$bizUtil.numberWithCommas(item.ukepMpadPrm.amount)
          item.ltpadYm1 = item.ltpadYm.substr(0, 4) + '.' + item.ltpadYm.substr(4)
          item.dspched = false

          switch (item.salesUkepScCd) {
            case '1':
              item.condition = '유지'
              break
            case '2':
              if (response.body.ukepScCd === '02') {
                item.condition = '유지'
              } else {
                item.condition = '미유지'
              }
              break
            case '3':
              item.condition = '미유지'
              break
            default:
              item.condition = '실효'
              break
          }
          if (scukepChkSpcfListVO.ukepScCd === '01' &&
              (item.salesUkepScCd === '2' || item.salesUkepScCd === '3' || item.salesUkepScCd === '4') &&
                item.padTms === item.exptPadTms) {
            item.ck = item.idx
          }
          if (scukepChkSpcfListVO.ukepScCd === '02' &&
              (item.salesUkepScCd === '3' || item.salesUkepScCd === '4') &&
              (item.padTms === item.exptPadTms || item.padTms === item.exptPadTms + 1)) {
            item.ck = item.idx
          }
          if (item.padMthCd === '3') {
            item.padMthCdNm = '자동이체'
          } else if (item.padMthCd === '2') {
            item.padMthCdNm = '직납/방문'
          } else if (item.padMthCd === '8') {
            item.padMthCdNm = '카드이체'
          } else if (item.padMthCd === '5') {
            item.padMthCdNm = '직역급여'
          } else if (item.padMthCd === '6') {
            item.padMthCdNm = '급여이체'
          }

          if (item.ck) {
            item.checked = true
          }
        })

        let tmpList = []
        for (let i = 0; i < responseList.length; i++) {
          tmpList = [responseList[i], ...tmpList]
        }

        this.pMUkepChkSpcf01VO = tmpList

        // 검색조건이 있는 경우 filter
        if (!lv_Vm.$bizUtil.isEmpty(lv_Vm.lv_inputParam.custNm)) {
          lv_Vm.pMUkepChkSpcf01VO = lv_Vm.pMUkepChkSpcf01VO.filter(item => item.custNm.indexOf(lv_Vm.lv_inputParam.custNm) >= 0)
        }

        this.totCnt = this.pMUkepChkSpcf01VO.length
        if (this.totCnt === 0) lv_Vm.isEmptyView = true

        // 발송내역 조회
        lv_Vm.fn_SelSmsDspchInpt()

        if (lv_Vm.isFirst) {
          // 해당예상유지율, 확정예상유지율 계산
          // lv_Vm.fn_ContNoDecrypt() // 복호화 후 계산
        }
      } else {
        this.pMUkepChkSpcf01VO = [];
        this.totCnt = 0;
        this.isEmptyView = true;
      }
    },

    /******************************************************************************
    * Function명 : fn_SelSmsDspchInpt
    * 설명       : 문자전송내역 조회
    *
    ******************************************************************************/
    fn_SelSmsDspchInpt () {
      let lv_Vm = this
      let pParams = {}
      const trnstId = 'txTSSPM07S1'
      const auth = 'S'

      let today = moment(new Date()).format('YYYYMMDDHHmmss')
      pParams.msgDspchTrtDtm = today

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if ( response && response.body) {
            let responseList = response.body.pMSmsDspchSVO
            for (let i in responseList) {
              for (let j in lv_Vm.pMUkepChkSpcf01VO) {
                // 현재 채널고객ID에 계약번호 입력
                if (lv_Vm.pMUkepChkSpcf01VO[j].contNo === responseList[i].mobslChnlCustId && responseList[i].mobslSrnId === lv_Vm.$options.screenId) {
                  lv_Vm.pMUkepChkSpcf01VO[j].dspched = true
                  lv_Vm.pMUkepChkSpcf01VO[j].dspchDate = moment(responseList[i].msgDspchTrtDtm.substr(0,8)).format('YYYY-MM-DD')
                  lv_Vm.pMUkepChkSpcf01VO[j].dspchTyp = responseList[i].msgDspchTypCd === 'S'? 'SMS': '터치온'
                  break
                }
              }
            }
          } 
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_UpdUkepChkSpcfClick()
    * 설명       : 예상유지율 반영 버튼
    ******************************************************************************/
    fn_UpdUkepChkSpcfClick (decContNo) {
      let lv_Vm = this
      lv_Vm.pMUkepChkSpcf01VO.forEach((item) => {
        if (item.checked) {
          if (lv_Vm.lv_inputParam.ukepScCd === '01') {
            item.exptPadTms = item.padTms
          } else {
            if (!(lv_Vm.lv_inputParam.ukepScCd === '02' && (item.padTms === item.exptPadTms))) {
              item.exptPadTms = item.padTms - 1
            }
          }
        } else {
          if (!(lv_Vm.lv_inputParam.ukepScCd === '01' && item.padTms > item.exptPadTms)) {
            item.exptPadTms = item.rlpadTms
          } 
        }
      })

      let inputData = {}
      let reData = [] // 복호화를 위한 리스트
      const trnstId = this.$pmUtil.getTrnstId()
      const auth = 'S'
      
      reData = _.cloneDeep(lv_Vm.pMUkepChkSpcf01VO)
      let i = 0
      for(i=0; i<reData.length; i++) {
        reData[i].contNo = decContNo[i]
      }

      inputData.sCUkepChkSpcf01VO = reData
      
      let commHeader = {
        eaiId: '',
        fahrTrnId: '',
        requestSystemCode: '',
        reqSrvcNm: 'SCUkepChkSpcfCtlImpl',
        reqMthdNm: 'updUkepChkSpcf',
        targetSystemCode: 'sli.ic',
        reqVONm: 'sli.ch.vo.sc.ukeprat.SCUkepChkSpcfListVO',
        resVONm: 'sli.ch.vo.sc.ukeprat.SCUkepChkSpcfListVO'
      }
      this.$pmUtil.eaiInvoke(inputData, trnstId, auth, commHeader, this.fn_UpdUkepChkSpcfClickHandler)
    },
    fn_UpdUkepChkSpcfClickHandler (response) {
      let lv_Vm = this
      if (!response || !response.body) {
        return
      }
      // 서버 데이터 가져오기
      if (response.body !== null) {
        lv_Vm.fn_UkepChkSpcfMoblByFC()  // 예상 유지율
      } else {
        let msg = '[' + response.msgComm.msgCd + ']  ' + response.msgComm.msgDesc
        this.$pmUtil.getFDPConfirm('s', msg)
      }
    },

  /******************************************************************************
    * Function명 : fn_ContNoDecrypt
    * 설명       : 계약번호 복호화
    * 작성자     : 엄기문
  ******************************************************************************/
    async fn_ContNoDecrypt(isUkep) {      
      // post 호출 전 Progress 타입 설정
      window.vue.getStore('progress').dispatch('PART')
      
      if (isUkep) this.isUkep = true
      else this.isUkep = false
      
      let data = []
      let enData = []
      if (!_.isEmpty(this.pMUkepChkSpcf01VO)) {
        for(let i=0; i<this.pMUkepChkSpcf01VO.length; i++) {
          data.push(this.pMUkepChkSpcf01VO[i].contNoEnc)
        }
        enData = await this.$commonUtil.getDecryptCall(this,data)
      }

      this.fn_UpdUkepChkSpcfClick(enData)
    },

    /******************************************************************************
    * Function명 : fn_UkepChkSpcfMoblByFC
    * 설명       : 유지체크명세 예상 유지율 호출함수
    *
    *****************************************************************************/
    fn_UkepChkSpcfMoblByFC () {
      let lv_Vm = this
      const trnstId = this.$pmUtil.getTrnstId()
      const auth = 'S'
      let inputData = lv_Vm.lv_inputParam
      
      let commHeader = {
        eaiId: '',
        fahrTrnId: '',
        requestSystemCode: '',
        reqSrvcNm: 'SCUkepChkSpcfMoblCtlImpl',
        reqMthdNm: 'selUkepChkSpcfMoblByFC',
        targetSystemCode: 'sli.ic',
        reqVONm: 'sli.ch.vo.sc.ukeprat.SCUkepChkSpcfMoblListVO',
        resVONm: 'sli.ch.vo.sc.ukeprat.SCUkepChkSpcfMoblListVO'
      }
      this.$pmUtil.eaiInvoke(inputData, trnstId, auth, commHeader, lv_Vm.fn_UkepChkSpcfMoblByFCHandler)
    },
    fn_UkepChkSpcfMoblByFCHandler (response) {
      let lv_Vm = this
      if (!response || !response.body) {
        return
      }

      // 서버 데이터 가져오기
      let idx = 0
      if (response.body !== null) {
        if (response.body.scukepChkSpcfMobl01VO !== null && response.body.scukepChkSpcfMobl02VO !== null && response.body.scukepChkSpcfMobl01VO.length >= 2) {
          lv_Vm.lv_scukepChkSpcfMobl01VO = response.body.scukepChkSpcfMobl01VO
          lv_Vm.lv_scukepChkSpcfMobl02VO = response.body.scukepChkSpcfMobl02VO

          // 임시 적용(해당유지율 그래프 미표시 테스트)
          if (lv_Vm.isFirst) {
            lv_Vm.lv_first01VO = lv_Vm.lv_scukepChkSpcfMobl01VO
            lv_Vm.isFirst = false
            if (!this.isUkep) {
              lv_Vm.fn_DetailResetBtnClick()
              return
            }
          }
          /*
          // 해당유지율 그래프(871)
          if (lv_Vm.isFirst) {
            lv_Vm.lv_first01VO = lv_Vm.lv_scukepChkSpcfMobl01VO
            lv_Vm.lv_graphData.push({Title: '2 ~ 13회(통산)', ukep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[0].avrUkepRat01)})
            lv_Vm.lv_graphData.push({Title: '14 ~ 25회(평균)', ukep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[0].avrUkepRat02)})

            if (lv_Vm.lv_graphData.length === 4) {
              lv_Vm.isFirst = false
              lv_Vm.isDcd = false
              if (lv_Vm.lv_index === '1') lv_Vm.lv_inputParam.ukepScCd = '01'
              lv_Vm.$nextTick(() => {
                // 원형 차트 생성
                for (let i in lv_Vm.lv_graphData) lv_Vm.fn_makeChartCreate(i)
                // 해당유지율 그래프 바텀시트 오픈
                lv_Vm.$refs.bottomSheetGraph.open()
              })
            } else {
              // 확정유지율 재계산
              lv_Vm.isDcd = true
              lv_Vm.lv_inputParam.ukepScCd = '02'
              lv_Vm.fn_ContNoDecrypt() // 계약번호 복호화 후 재계산
            }
            return
          }
          */
          // 환산월초 기준유지율 데이터 세팅(877)
          // else {
            lv_Vm.lv_exptTableData = []
            lv_Vm.lv_exptTableData.push({round: '2 ~ 13회(통산)', ukep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[0].agrtUkepRat01), exptUkep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[1].agrtUkepRat01)})
            // lv_Vm.lv_exptTableData.push({round: '14 ~ 25회(통산)', ukep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[0].agrtUkepRat02), exptUkep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[1].agrtUkepRat02)})
            // lv_Vm.lv_exptTableData.push({round: '26 ~ 37회(통산)', ukep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[0].agrtUkepRat03), exptUkep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[1].agrtUkepRat03)})
            // lv_Vm.lv_exptTableData.push({round: '38 ~ 49회(통산)', ukep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[0].agrtUkepRat04), exptUkep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[1].agrtUkepRat04)})
            // lv_Vm.lv_exptTableData.push({round: '2 ~ 49회(통산)', ukep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[0].agrtUkepRat05), exptUkep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[1].agrtUkepRat05)})
            lv_Vm.lv_exptTableData.push({round: '2 ~ 13회(평균)', ukep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[0].avrUkepRat01), exptUkep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[1].avrUkepRat01)})
            lv_Vm.lv_exptTableData.push({round: '14 ~ 25회(평균)', ukep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[0].avrUkepRat02), exptUkep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[1].avrUkepRat02)})
            lv_Vm.lv_exptTableData.push({round: '26 ~ 37회(평균)', ukep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[0].avrUkepRat04), exptUkep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[1].avrUkepRat04)})
            lv_Vm.lv_exptTableData.push({round: '38 ~ 49회(평균)', ukep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[0].avrUkepRat06), exptUkep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[1].avrUkepRat06)})
            lv_Vm.lv_exptTableData.push({round: '2 ~ 49회(평균)', ukep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[0].avrUkepRat07), exptUkep: Number(lv_Vm.lv_scukepChkSpcfMobl01VO[1].avrUkepRat07)})
            lv_Vm.lv_exptTableData.push({round: '2 ~ 7회(합산)', ukep: lv_Vm.lv_scukepChkSpcfMobl01VO[0].addupUkepRat01, exptUkep: lv_Vm.lv_scukepChkSpcfMobl01VO[1].addupUkepRat01})
            lv_Vm.lv_exptTableData.push({round: '2 ~ 13회(합산)', ukep: lv_Vm.lv_scukepChkSpcfMobl01VO[0].addupUkepRat02, exptUkep: lv_Vm.lv_scukepChkSpcfMobl01VO[1].addupUkepRat02})
            lv_Vm.lv_exptTableData.push({round: '14 ~ 25회(합산)', ukep: lv_Vm.lv_scukepChkSpcfMobl01VO[0].addupUkepRat03, exptUkep: lv_Vm.lv_scukepChkSpcfMobl01VO[1].addupUkepRat03})
            lv_Vm.lv_exptTableData.push({round: '26 ~ 37회(합산)', ukep: lv_Vm.lv_scukepChkSpcfMobl01VO[0].addupUkepRat06, exptUkep: lv_Vm.lv_scukepChkSpcfMobl01VO[1].addupUkepRat06})
            lv_Vm.lv_exptTableData.push({round: '38 ~ 49회(합산)', ukep: lv_Vm.lv_scukepChkSpcfMobl01VO[0].addupUkepRat08, exptUkep: lv_Vm.lv_scukepChkSpcfMobl01VO[1].addupUkepRat08})
            lv_Vm.lv_exptTableData.push({round: '2 ~ 25회(합산)', ukep: lv_Vm.lv_scukepChkSpcfMobl01VO[0].addupUkepRat04, exptUkep: lv_Vm.lv_scukepChkSpcfMobl01VO[1].addupUkepRat04})
            lv_Vm.lv_exptTableData.push({round: '2 ~ 37회(합산)', ukep: lv_Vm.lv_scukepChkSpcfMobl01VO[0].addupUkepRat05, exptUkep: lv_Vm.lv_scukepChkSpcfMobl01VO[1].addupUkepRat05})
            lv_Vm.lv_exptTableData.push({round: '2 ~ 49회(합산)', ukep: lv_Vm.lv_scukepChkSpcfMobl01VO[0].addupUkepRat07, exptUkep: lv_Vm.lv_scukepChkSpcfMobl01VO[1].addupUkepRat07})
            
            // 2 ~ 13회(평균), 2 ~ 25회(평균) 계산값 임의 저장: 초기화 팝업 호출 시 필요
            lv_Vm.lv_exptTableDataEtc = []
            lv_Vm.lv_exptTableDataEtc.push({round: '2 ~ 25회(평균)', ukep: lv_Vm.lv_scukepChkSpcfMobl01VO[0].avrUkepRat03, exptUkep: lv_Vm.lv_scukepChkSpcfMobl01VO[1].avrUkepRat03})
            lv_Vm.lv_exptTableDataEtc.push({round: '2 ~ 37회(평균)', ukep:lv_Vm.lv_scukepChkSpcfMobl01VO[0].avrUkepRat05, exptUkep: lv_Vm.lv_scukepChkSpcfMobl01VO[1].avrUkepRat05})
          // }

          // 회차별유지율(877)
          lv_Vm.lv_scukepChkSpcfMobl02VO.forEach((item) => {
            item.cltqtClctCnvlBomthAmtCommas = lv_Vm.$bizUtil.numberWithCommas(item.cltqtClctCnvlBomthAmt.amount)
            item.ukeqtClctCnvlBomthAmtCommas = lv_Vm.$bizUtil.numberWithCommas(item.ukeqtClctCnvlBomthAmt.amount)
            item.exptCnvlAmtCommas = lv_Vm.$bizUtil.numberWithCommas(item.exptCnvlAmt.amount)
            item.curTmsclctYm = item.curTms + '회 (' + item.clctYm.substring(2, 4) + '.' + item.clctYm.substring(4, 6) + ')'
          })

          // 해당 탭
          if (lv_Vm.lv_index === '1') {
            // 예상유지율 결과 팝업 오픈
            this.$refs.refPM872D.fn_OpenBottomSheet('877')
          } else {
            // 확정 탭
            this.$refs.refPM876D.fn_OpenBottomSheet('877')
          }
        } else {
          this.$pmUtil.getFDPConfirm('s', this.$t('pm')['EPMC049'])
        }
      } else {
        let msg = '[' + response.msgComm.msgCd + ']  ' + response.msgComm.msgDesc
        this.$pmUtil.getFDPConfirm('s', msg)
      }
    },

    /*********************************************************
     * Function명 : fn_makeChartCreate
     * 설명       : 원형 차트 생성(rMate)
     *********************************************************/
    fn_makeChartCreate (i) {
      let lv_Vm = this
      chartData = []
      layoutStr = ''
     
      // 유지율에 따른 색상코드값 변경
      if (lv_Vm.lv_graphData[i].ukep >= 90) lv_Vm.firstColor = '0x66C8C9'
      else if (lv_Vm.lv_graphData[i].ukep >= 85 && lv_Vm.lv_graphData[i].ukep < 90) lv_Vm.firstColor = '0xFFB60D'
      else if (lv_Vm.lv_graphData[i].ukep >= 80 && lv_Vm.lv_graphData[i].ukep < 85) lv_Vm.firstColor = '0xFB7655'

      // 라벨 데이터 세팅
      lv_Vm.lv_graphData[i].label = String(lv_Vm.lv_graphData[i].ukep) + '%'
      chartData = [lv_Vm.lv_graphData[i]]
      chartData.push({Title: '', ukep: 100 - lv_Vm.lv_graphData[i].ukep, label:''})
      layoutStr = `<rMateChart backgroundColor="#FFFFFF"  borderStyle="none">
                    <NumberFormatter id="fmt` + i + `"/>
                    <Pie2DChart innerRadius = "0.7" showDataTips="false" explodable="false" fontSize="30" >
                      <series>
                        <Pie2DSeries field="ukep" labelField="label" labelPosition="inside" color="#333333" startAngle="270" insideLabelRatio="0" alwaysShowInsideLabel="true">
                          <showDataEffect>
                            <SeriesZoom duration="1000" />
                          </showDataEffect>
                          <fills>
                            <SolidColor color="` + lv_Vm.firstColor + `" alpha="` + lv_Vm.firstAlpha + `" />
                            <SolidColor color="` + lv_Vm.secondColor + `" alpha="` + lv_Vm.secondAlpha + `" />
                          </fills>
                        </Pie2DSeries>
                      </series>
                    </Pie2DChart>
                  </rMateChart>`
      rMateChartH5.create('MSPPM870M_' + i, 'chartPie' + i, 'rMateOnLoadCallFunction=chartReadyHandler', '100%', '100%')
      rMateChartH5.registerTheme(rMateChartH5.themes)
    },

    /*********************************************************
     * Function명 : fn_DetailResetBtnClick
     * 설명       : 예상유지율 초기화
     *********************************************************/
    fn_DetailResetBtnClick () {
      let lv_Vm = this

      // 초회 진입 그래프 제거 테스트
      if (lv_Vm.lv_first01VO.length === 0) {
        lv_Vm.fn_ContNoDecrypt()
        return
      }

      // 예상유지율 반영 초기화(873)
      lv_Vm.lv_initTableData = []
      lv_Vm.lv_initTableData.push({round: '2 ~ 13회(통산)', ukep: lv_Vm.lv_first01VO[1].agrtUkepRat01, exptUkep: (lv_Vm.lv_exptTableData.length > 0)? lv_Vm.lv_exptTableData[0].exptUkep: lv_Vm.lv_first01VO[0].agrtUkepRat01})
      // lv_Vm.lv_initTableData.push({round: '14 ~ 25회(통산)', ukep: lv_Vm.lv_first01VO[1].agrtUkepRat02, exptUkep: (lv_Vm.lv_exptTableData.length > 0)? lv_Vm.lv_exptTableData[1].exptUkep: lv_Vm.lv_first01VO[0].agrtUkepRat02})
      // lv_Vm.lv_initTableData.push({round: '26 ~ 37회(통산)', ukep: lv_Vm.lv_first01VO[1].agrtUkepRat03, exptUkep: (lv_Vm.lv_exptTableData.length > 0)? lv_Vm.lv_exptTableData[2].exptUkep: lv_Vm.lv_first01VO[0].agrtUkepRat03})
      // lv_Vm.lv_initTableData.push({round: '38 ~ 49회(통산)', ukep: lv_Vm.lv_first01VO[1].agrtUkepRat04, exptUkep: (lv_Vm.lv_exptTableData.length > 0)? lv_Vm.lv_exptTableData[3].exptUkep: lv_Vm.lv_first01VO[0].agrtUkepRat04})
      // lv_Vm.lv_initTableData.push({round: '2 ~ 49회(통산)', ukep: lv_Vm.lv_first01VO[1].agrtUkepRat05, exptUkep: (lv_Vm.lv_exptTableData.length > 0)? lv_Vm.lv_exptTableData[4].exptUkep: lv_Vm.lv_first01VO[0].agrtUkepRat05})
      lv_Vm.lv_initTableData.push({round: '2 ~ 13회(평균)', ukep: lv_Vm.lv_first01VO[1].avrUkepRat01, exptUkep: (lv_Vm.lv_exptTableData.length > 0)? lv_Vm.lv_exptTableData[1].exptUkep: lv_Vm.lv_first01VO[0].avrUkepRat01})
      lv_Vm.lv_initTableData.push({round: '14 ~ 25회(평균)', ukep: lv_Vm.lv_first01VO[1].avrUkepRat02, exptUkep: (lv_Vm.lv_exptTableData.length > 0)? lv_Vm.lv_exptTableData[2].exptUkep: lv_Vm.lv_first01VO[0].avrUkepRat02})
      lv_Vm.lv_initTableData.push({round: '2 ~ 25회(평균)', ukep: lv_Vm.lv_first01VO[1].avrUkepRat03, exptUkep: (lv_Vm.lv_exptTableDataEtc.length > 0)? lv_Vm.lv_exptTableDataEtc[0].exptUkep: lv_Vm.lv_first01VO[0].avrUkepRat03})
      lv_Vm.lv_initTableData.push({round: '26 ~ 37회(평균)', ukep: lv_Vm.lv_first01VO[1].avrUkepRat04, exptUkep: (lv_Vm.lv_exptTableData.length > 0)? lv_Vm.lv_exptTableData[3].exptUkep: lv_Vm.lv_first01VO[0].avrUkepRat04})
      lv_Vm.lv_initTableData.push({round: '2 ~ 37회(평균)', ukep: lv_Vm.lv_first01VO[1].avrUkepRat05, exptUkep: (lv_Vm.lv_exptTableDataEtc.length > 0)? lv_Vm.lv_exptTableDataEtc[1].exptUkep: lv_Vm.lv_first01VO[0].avrUkepRat05})
      lv_Vm.lv_initTableData.push({round: '38 ~ 49회(평균)', ukep: lv_Vm.lv_first01VO[1].avrUkepRat06, exptUkep: (lv_Vm.lv_exptTableData.length > 0)? lv_Vm.lv_exptTableData[4].exptUkep: lv_Vm.lv_first01VO[0].avrUkepRat06})
      lv_Vm.lv_initTableData.push({round: '2 ~ 49회(평균)', ukep: lv_Vm.lv_first01VO[1].avrUkepRat07, exptUkep: (lv_Vm.lv_exptTableData.length > 0)? lv_Vm.lv_exptTableData[5].exptUkep: lv_Vm.lv_first01VO[0].avrUkepRat07})

      // 해당 탭
      if (lv_Vm.lv_index === '1') {
        // 예상유지율 초기화 팝업 오픈
        this.$refs.refPM872D.fn_OpenBottomSheet('873')
      } else {
        this.$refs.refPM876D.fn_OpenBottomSheet('873')
      }
    },

    /*********************************************************
     * Function명: fn_OpenMSPPM871P
     * 설명: 상세조회 팝업 호출
     *********************************************************/
    fn_OpenMSPPM871P () {
      this.isSearched = false
      this.popup871 = this.$bottomModal.open(MSPPM871P, {
        properties: {
          pPage: this.$options.screenId,
          pOrgData: this.pOrgData870M, // 최근 조직 조회 데이터
          pIsMngr: this.isMngr, // 지점장 여부
          pSearchCustNm: this.lv_inputParam.custNm, // 고객명
          pSel_group1: String(this.sel_group1), // 유지횟수 시작회차
          pSel_group2: String(this.sel_group2), // 종료회차
          pInqrStndCd: this.lv_inputParam.inqrStndCd,  // 산출기준
          pStatScCd: this.lv_inputParam.statScCd,  // 납입상태
          pPrdtLclsfCd: this.lv_inputParam.prdtLclsfCd,  // 상품구분
          pInqrScCd: this.lv_inputParam.inqrScCd,  // 납입방법
          pTrsfDdScVal: this.lv_inputParam.trsfDdScVal // 이체일
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup871)
            // 바텀시트 초기화
            if (this.lv_index === '1') this.$refs.refPM872D.fn_InitBottomSheet()
            else this.$refs.refPM876D.fn_InitBottomSheet()

            // 마지막 조직 조회 데이터 셋팅
            this.pOrgData870M = pData.rtnData.pOrgData
            // 나머지 조회 조건 데이터 세팅
            this.lv_inputParam.custNm = pData.rtnData.searchCustNm // 고객명
            this.sel_group1 = Number(pData.rtnData.sel_group1)  // 유지횟수
            this.sel_group2 = Number(pData.rtnData.sel_group2)
            this.lv_inputParam.inqrStndCd = pData.rtnData.inqrStndCd // 산출기준
            this.lv_inputParam.statScCd = pData.rtnData.statScCd // 납입상태
            this.lv_inputParam.prdtLclsfCd = pData.rtnData.prdtLclsfCd //상품구분
            this.lv_inputParam.inqrScCd = pData.rtnData.inqrScCd // 납입방법
            this.lv_inputParam.trsfDdScVal = pData.rtnData.trsfDdScVal // 이체일
            // 유지횟수 상세
            if (this.lv_inputParam.ukepScCd === '01') {
              this.lv_inputParam.inqrStrYm = moment(this.inqrStrYm_Init).subtract(this.sel_group1 - 1, 'months').format('YYYYMM')
              this.lv_inputParam.inqrEndYm = moment(this.inqrStrYm_Init).subtract(this.sel_group2 - 1, 'months').format('YYYYMM')
            } else {
              this.lv_inputParam.inqrStrYm = moment(this.inqrStrYm_Init).subtract(this.sel_group1, 'months').format('YYYYMM')
              this.lv_inputParam.inqrEndYm = moment(this.inqrStrYm_Init).subtract(this.sel_group2, 'months').format('YYYYMM')
            }

            // 조회조건 텍스트 세팅
            this.lv_ReturnStr = pData.srchOpt
            // 조회 조건 텍스트 표시
            this.isSearched = true
            // 검색 초기화 여부
            this.isInit = pData.isInit

            // post 호출 전 Progress 타입 설정
            window.vue.getStore('progress').dispatch('NONE')
            this.loadingMsg = '유지 정보 조회중'
            this.fn_SetProgressMsgPop(true)

            // 유지체크명세 조회
            this.fn_Submit()
          }
        }
      })
    },

    /******************************************************************************
      * Function명  : fn_SetProgressMsgPop
      * 설명        : 로딩 메시지 설정
      * Params      : pShowFlag - Progress 화면 설정 Flag
      ******************************************************************************/
    fn_SetProgressMsgPop(pShowFlag) {
      this.isReading = pShowFlag
    },

    /******************************************************************************
      * Function명  : fn_topButtonBottom
      * 설명        : 바텀시트 표시 중 스크롤 버튼을 위로 이동
      ******************************************************************************/
    fn_topButtonBottom (param) {
      if (param.positionUp) this.topButtonBottom = 150
      else this.topButtonBottom = 0
    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              if (this.$route.query?._isMenuItem) {
                this.$router.push({name: 'MSPBC002M'})
                return
              }
              this.$router.go(-1)
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },
   
  }
}
</script>
